<template>
  <asterix-application-layout>
    <template #sidebar>
      <asterix-sidebar v-model="isSidebarOpen">
        <sun-sidebar-group class="mt-8">
          <sun-sidebar-nav-item :to="analytics">
            <template #icon><analytics-svg class="w-4" /></template>
            <span class="font-bold">Analytics</span>
          </sun-sidebar-nav-item>
          <sun-sidebar-nav-item :to="admin">
            <template #icon><admin-svg class="w-4" /></template>
            Admin
            <template #children>
              <sun-sidebar-nav-item :to="users"> Users </sun-sidebar-nav-item>
              <sun-sidebar-nav-item v-if="isAdminOrAdop" :to="clientList"> Wallets </sun-sidebar-nav-item>
              <sun-sidebar-nav-item v-else :to="wallet"> Wallet </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>
          <sun-sidebar-nav-item :to="demand">
            <template #icon><demand-svg class="w-4" /></template>
            Demand
            <template #children>
              <!-- This section has to be hidden for a while
              <sun-sidebar-nav-item :to="advertiser"> Advertisers </sun-sidebar-nav-item>
              -->
              <sun-sidebar-nav-item :to="campaign"> Campaigns </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>
        </sun-sidebar-group>
      </asterix-sidebar>
    </template>

    <template #header>
      <asterix-topbar class="z-20" @change="isSidebarOpen = !isSidebarOpen" />
    </template>
  </asterix-application-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import AsterixApplicationLayout from '@/components/templates/AsterixApplicationLayout';
import AsterixSidebar from '@/components/molecules/shared/AsterixSidebar/AsterixSidebar';
import AsterixTopbar from '@/components/molecules/shared/AsterixTopbar';
import SunSidebarNavItem from '@/components/molecules/SunSidebarNavItem';
import AnalyticsSvg from '@/components/icons/AnalyticsSvg';
import DemandSvg from '@/components/icons/DemandSvg';
import AdminSvg from '@/components/icons/AdminSvg';
import { admin } from '@/router/private/modules/socialAudience/admin';
import { advertiser } from '@/router/private/modules/socialAudience/demand/advertiser';
import { analytics } from '@/router/private/modules/socialAudience/analytics';
import { campaign } from '@/router/private/modules/socialAudience/demand/campaign';
import { demand } from '@/router/private/modules/socialAudience/demand';
import { list as users } from '@/router/private/modules/socialAudience/admin/users/list.js';
import { transactionsList as wallet } from '@/router/private/modules/socialAudience/admin/wallet/transactionsList';
import { clientList } from '@/router/private/modules/socialAudience/admin/wallet/clientList.js';
import { USER } from '@/store/modules/auth/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

export default {
  name: 'SocialAudienceIndex',
  components: {
    AdminSvg,
    AnalyticsSvg,
    AsterixApplicationLayout,
    AsterixSidebar,
    AsterixTopbar,
    DemandSvg,
    SunSidebarNavItem,
  },
  data: () => ({
    isSidebarOpen: true,
    admin,
    advertiser,
    analytics,
    campaign,
    demand,
    users,
    wallet,
    clientList,
  }),
  computed: {
    ...mapGetters({
      user: USER,
    }),
    rolesAuth() {
      return this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.SOCIAL_AUDIENCE.id);
    },
    isAdminOrAdop() {
      return this.rolesAuth?.role === ROLES.ADMIN.id || this.rolesAuth?.role === ROLES.AD_OPS.id;
    },
  },
};
</script>
