import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;

export const edit = {
  path: ':advertiserId',
  name: `${basePath}-edit-advertiser`,
  component: () =>
    import(/* webpackChunkName: "sa-advertiser" */ '@/views/private/modules/socialAudience/advertiser/AdvertiserEdit'),
  meta: {
    requiresAuth: true,
    title: 'Advertiser',
    breadcrumbs: new BreadcrumbItem('Edit Advertiser', { name: `${basePath}-edit-advertiser` }, {}),
    contextSecure: CONTEXTS.SOCIAL_AUDIENCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
  },
  props: true,
};
